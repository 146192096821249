* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root {
  height: 100vh;
}

body {
  margin: 0;
  background: #FFFFFF;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  font-weight: 300;
  height: fit-content;
  font-family: "halyard-display", sans-serif;
  scrollbar-width: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  display: none !important; /* Safari and Chrome */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a { 
  text-decoration: none;
}

p {
  color: #3F3F3F;
}